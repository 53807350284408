import React from 'react';
import adc_logo from '../assets/adc-logo.png';
import ai_logo from '../assets/ai-logo.png';

function About() {
  return (
    <div className="flex px-4 text-center justify-center bg-white py-20 shadow-lg relative isolate z-10">
      <div className="flex items-center max-w-2xl">
        <div className="">
          <p className="text-base font-semibold leading-7 text-indigo-600">
            Made with love in Amsterdam
          </p>
          <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
            Over Empathie.ai
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Empathie.ai is een onderzoeksproject bedacht vanuit Ai.nl en
            Amsterdam Data Collective om te kijken hoe AI-taalmodellen kunnen
            helpen bij het creëren van een prettige werkomgeving. Voor vragen,
            stuur ons een{' '}
            <a
              href="mailto:info@ai.nl?subject=Empathie.ai"
              className="underline"
            >
              e-mail
            </a>
            .
          </p>
          <div className="container mx-auto flex justify-center items-center space-x-8 p-10">
            <a
              href="https://www.ai.nl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={ai_logo} className="h-12 w-auto" alt="AI Logo" />
            </a>
            <a
              href="https://www.amsterdamdatacollective.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={adc_logo} className="h-12 w-auto" alt="ADC Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
