import React, { useState } from 'react';
import './App.css';
import Header from './components/Header';
import TextBox from './components/TextBox';
import About from './components/About';
import Footer from './components/Footer';
import axios from 'axios';

function App() {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleApiCall = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        //'http://localhost:3001/api/empathize',
        'https://empathie-server.azurewebsites.net/api/empathize',
        {
          text: inputText,
        }
      );

      const empatheticText = response.data.empatheticText;
      setOutputText(empatheticText);
    } catch (error) {
      console.error('Error fetching response:', error);
      setOutputText('Oeps, er is iets verkeerd gegaan. Probeer het opnieuw.');
    }
    setLoading(false);
  };

  return (
    <div>
      <Header />
      <TextBox
        setInputText={setInputText}
        onSubmit={handleApiCall}
        isLoading={isLoading}
        outputText={outputText}
      />
      <About />
      <Footer />
    </div>
  );
}

export default App;
