import React from 'react';
import empathie_ai_logo from '../assets/empathie-ai-logo.png';

function Header() {
  return (
    <header className="absolute inset-x-0 top-0">
      <nav
        className="flex items-center w-full justify-center text-center p-6"
        aria-label="Global"
      >
        <div className="flex">
          <img className="h-16 w-auto" src={empathie_ai_logo} alt="" />
        </div>
      </nav>
    </header>
  );
}

export default Header;
